import React, { useEffect, useState } from "react"
import "./AllProducts.css"
import axios from "axios"
import Table from "react-bootstrap/Table"
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import AbmButtons from "../../ABMbotones/abmButtons"
import Cookies from "js-cookie"
import { Form, Pagination, Nav, Modal, Spinner } from "react-bootstrap"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import IconFilter from "../../../Assets/Img/filter_icon.svg";
import IconFilterNum from "../../../Assets/Img/filter_icon_num.svg";
import { utils, writeFile } from "xlsx"
import { Tooltip } from "@mui/material";
import ItemRow from "./Components/ItemRow"
import ProductDeleteHandlerModal from "./Components/ProductDeleteHandlerModal"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Divider from '@mui/material/Divider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import NestedModal from './ModalProduct.jsx';
import PdfExportModal from "./Components/PdfExportModal.jsx";




const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export const AllProducts = () => {
	const [tagsAndProductsWithoutTag, setTagsAndProductsWithoutTag] = useState([])
	const [tagsAndProductsWithoutTagFiltered, setTagsAndProductsWithoutTagFiltered] = useState([])
	const [productSelected, setProductSelected] = useState(null)
	const [providerSelected, setProviderSelected] = useState(null)
	const [isAdmin, setIsAdmin] = useState("")
	const [search, setSearch] = useState("") // Para almacenar la búsqueda
	const [allCategories, setAllCategories] = useState("")


	//Paginacion
	const productsPerPage = 5
	const [currentPage, setCurrentPage] = useState(1)
	

	// Estados para AnimacionSuccessful
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")
	const [variable, setVariable] = useState(false)

	//Proveedores
	const [selectProviders, setSelectProviders] = useState([])

	//Radiobutton
	const [option, setOption] = useState("")
	const [tabSection, setTabSection] = useState("Bioquimicos")
	const [isBioSection, setIsBioSection] = useState(true)
	const [showVariosABM, setShowVariosABM] = useState(false)
	const [showButtonBio, setShowButtonBio] = useState(true)
	const [showButtonVarios, setShowButtonVarios] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	// Modal de manejo de eliminacion de producto
	const [showProductDeleteHandlerModal, setShowProductDeleteHandlerModal] = useState(false)
	const [ordersThatContainsProduct, setOrdersThatContainsProduct] = useState([])



	// Estado para el modal de carga de productos
	const [openNestedModal, setOpenNestedModal] = useState(false);

	const handleOpenNestedModal = () => setOpenNestedModal(true);
	const handleCloseNestedModal = () => setOpenNestedModal(false);

	// Estados para manejar el modal de exportación a PDF
	const [openPdfExportModal, setOpenPdfExportModal] = useState(false);

	const handleOpenPdfExportModal = () => setOpenPdfExportModal(true);
	const handleClosePdfExportModal = () => setOpenPdfExportModal(false);



	useEffect(() => {
		let timeoutId

		const getDataProducts = async () => {
			const token = Cookies.get("token")

			const response = await axios.get(`${BACK_APP_URI}/tags`, {
				headers: {
					Authorization: `${token}`,
				},
			})
			setIsAdmin(response.data.userdata)

			const allTags = response.data.tags

			if (isBioSection) {
				const tagsAndProductsWithoutTagArr = [...allTags.filter(tag => tag.name !== "-" && tag.isProducts), ...allTags.find(tag => tag.name === "-").products]
				setTagsAndProductsWithoutTag(tagsAndProductsWithoutTagArr)
			} else {
				const tagsAndProductsWithoutTagArr = [
					...allTags.filter(tag => tag.name !== "-" && !tag.isProducts),
					...allTags.find(tag => tag.name === "-").otherProducts,
				]

				setTagsAndProductsWithoutTag(tagsAndProductsWithoutTagArr)
			}
		}

		clearTimeout(timeoutId)
		timeoutId = setTimeout(() => {
			getDataProducts()
		}, 500)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [variable, isBioSection])

	useEffect(() => {
		function filterProducts(arr) {
			return arr.filter(
				product =>
					(product.code && product.code.toString().includes(search)) ||
					(product.name && product.name.toLowerCase().includes(search.toLowerCase())) ||
					(product.brand && product.brand.toLowerCase().includes(search.toLowerCase())) ||
					(product.quantity && product.quantity.toString().includes(search)) ||
					(product.unitType && product.unitType.name.toLowerCase().includes(search.toLowerCase())) ||
					(product.ubn && product.ubn.toString().includes(search)) ||
					(product.provider.name && product.provider.name.toLowerCase().includes(search.toLowerCase())) ||
					new Date(product.expireDate).toLocaleDateString("es-AR").includes(search)
			)
		}

		setTagsAndProductsWithoutTagFiltered(filterProducts(tagsAndProductsWithoutTag))
	}, [search, tagsAndProductsWithoutTag])

	useEffect(() => {
		const getDataProviders = async () => {
			const token = Cookies.get("token")
			try {
				const result = await axios.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})

				const selectIdProviders = result.data.provider.map(provider => ({
					_id: provider._id,
					name: provider.name,
				}))

				setSelectProviders(selectIdProviders)
				setIsLoaded(true)
			} catch (error) {
				console.error(error)
			}
		}

		getDataProviders()

		const getDataCategories = async () => {
			const token = Cookies.get("token")
			try {
				const result = await axios.get(`${BACK_APP_URI}/categories`, {
					headers: {
						Authorization: `${token}`,
					},
				})

				const allCategories = result.data.categories.map(category => ({
					_id: category._id,
					name: category.name,
				}))

				setAllCategories(allCategories)
			} catch (error) {
				console.error(error)
			}
		}

		getDataCategories()
	}, [])

	const indexOfLastProduct = currentPage * productsPerPage
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage
	const currentProducts = tagsAndProductsWithoutTagFiltered.slice(indexOfFirstProduct, indexOfLastProduct)
	const totalPages = Math.ceil(tagsAndProductsWithoutTag.length / productsPerPage)
	// Calcula si se muestra el botón de la primera página
	const showFirstPage = currentPage > 1;

	// Calcula si se muestra el botón de la última página
	const showLastPage = currentPage < totalPages - 2;

	// Genera un rango de páginas alrededor del número actual
	const pageRange = Array.from({ length: 5 }, (_, i) => currentPage - 2+ i);

	// Filtra las páginas dentro del rango para mostrarlas
	const visiblePages = pageRange.filter(page => page >= 1 && page <= totalPages);

	function refreshPage() {
		// window.location.reload(false);
		setVariable(!variable)
	}

	const select = product => {
		setProductSelected(product._id)
	}

	const deselect = () => {
		setProductSelected(null)
	}

	const deleteProduct = async id => {
		const res = await axios({
			method: "get",
			url: `${BACK_APP_URI}/pedidos/containsProduct/${productSelected}?typeOfProduct=product`,
		})

		if (res.data.containsProduct) {
			setOrdersThatContainsProduct(res.data.orders)
			setShowProductDeleteHandlerModal(true)
		} else {
			axios({
				method: "delete",
				url: `${BACK_APP_URI}/productos/delete/${productSelected}`,
			})
				.then(data => {
					setVariable(true)
					setErrorBool(false)
					setAvisomsg("Producto eliminado correctamente")
				})
				.catch(error => {
					console.error(error)
				})
		}
	}

	const deleteOtherProduct = async id => {
		const res = await axios({
			method: "get",
			url: `${BACK_APP_URI}/pedidos/containsProduct/${productSelected}?typeOfProduct=otherProduct`,
		})

		if (res.data.containsProduct) {
			setOrdersThatContainsProduct(res.data.orders)
			setShowProductDeleteHandlerModal(true)
		} else {
			axios({
				method: "delete",
				url: `${BACK_APP_URI}/otros_productos/delete/${productSelected}`,
			})
				.then(data => {
					setVariable(true)
					setErrorBool(false)
					setAvisomsg("Producto eliminado correctamente")
				})
				.catch(error => {
					console.error(error)
				})
		}
	}

	const deleteAllProductsById = () => {
		if (isBioSection) {
			axios({
				method: "delete",
				url: `${BACK_APP_URI}/productos/deleteByProvId/${providerSelected}`,
			})
				.then(data => {
					setVariable(true)
					setErrorBool(false)
					setAvisomsg("Productos eliminados!")
				})
				.catch(error => {
					console.log(error)
				})
		} else {
			axios({
				method: "delete",
				url: `${BACK_APP_URI}/otros_productos/deleteByProvId/${providerSelected}`,
			})
				.then(data => {
					setVariable(true)
					setErrorBool(false)
					setAvisomsg("Productos eliminados correctamente")
				})
				.catch(error => {
					console.log(error)
				})
		}
	}

	//---------------------------HANDLERS-------------------------------------

	const handleTabChange = section => {
		if (section === tabSection) return

		setTabSection(section)
		setTagsAndProductsWithoutTag([])
		deselect()
		setCurrentPage(1)

		if (section === "Bioquimicos") {
			setIsBioSection(true)
		} else if (section === "Varios") {
			setIsBioSection(false)
		}
	}

	const handleExportProductsSheet = () => {
		const productsData = tagsAndProductsWithoutTag.map(product => ({
			Codigo: product.code || "-",
			Nombre: product.name,
			Marca: product.brand || "-",
			Cantidad: product.quantity,
			TipoUnidad: product?.unitType?.name || product?.measure?.name,
			Lotes: product?.lots?.length || "-",
			Nbu: product.ubn || "-",
			Proveedor: product.provider.name,
		}))

		const worksheet = utils.json_to_sheet(productsData)

		// Crear el objeto workbook y asignar el worksheet
		const workbook = utils.book_new()
		utils.book_append_sheet(workbook, worksheet, "Orden de Compra")

		// Guardar el archivo Excel
		writeFile(workbook, `Lista_Productos.xlsx`)
	}

	const handleProviderChange = value => {
		setProviderSelected(value)
		console.log("Provider? ", value)
	}

	const handleSortAZ = () => {
		setTagsAndProductsWithoutTagFiltered(tagsAndProductsWithoutTag.sort((a, b) => a.name.localeCompare(b.name)))
	}

	const handleSortCodeAsc = () => {
		setTagsAndProductsWithoutTagFiltered(tagsAndProductsWithoutTag.sort((a, b) => a.code - b.code))
	}

	function isEven(n) {
		return n % 2 == 0
	}


	/* Menu desplegable acciones */


	const StyledMenu = styled((props) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 180,
			color:
				theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0',
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 16,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5),
				},
				'&:active': {
					backgroundColor: alpha(
						theme.palette.primary.main,
						theme.palette.action.selectedOpacity,
					),
				},
			},
		},
	}));


	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};


	return (
		<div className="main-container">
			<div className="header--container--row">
				<h1 className="title--header">Productos</h1>
				<div className="all-product-row">
					<Form className="">
						<Form.Group controlId="exampleForm.ControlInput1">
							<Form.Control
								className="p-2"
								type="text"
								value={search}
								onChange={e => {
									setSearch(e.target.value)
									setCurrentPage(1)
								}}
								placeholder="Buscar..."
							/>
						</Form.Group>

					</Form>
					<Button className='button-menu-prod'
						id="demo-customized-button"
						aria-controls={open ? 'demo-customized-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						variant="contained"
						disableElevation
						onClick={handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Acciones
					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'demo-customized-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						<div>
							<h3 className='title-menu-prod'>Productos</h3>
							<MenuItem
								component={Link}
								to="/upload"
								onClick={handleClose}
								disableRipple
							>
								<DriveFolderUploadOutlinedIcon />
								Cargar Productos
							</MenuItem>
							<MenuItem
								component={Link}
								to="/productos/add"
								onClick={handleClose}
								disableRipple
							>
								<AddCircleOutlineIcon />
								Agregar producto
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
							<h3 className='title-menu-prod'>Exportar</h3>
							<MenuItem
								onClick={() => {
									handleOpenPdfExportModal();
									handleClose();  // Cierra el menú desplegable
								}}
								disableRipple
							>
								<PictureAsPdfOutlinedIcon />
								Exportar .pdf
							</MenuItem>
							{isBioSection && (
								<MenuItem
									onClick={() => {
										handleExportProductsSheet();
										handleClose();
									}}
									disableRipple
								>
									<StickyNote2OutlinedIcon />
									Exportar .xlsx
								</MenuItem>
							)}
						</div>
					</StyledMenu>
				</div>
			</div>

			<div className="card--container">
				<div className="card--header--row">
					<ToggleButtonGroup
						value={tabSection}
						exclusive
						onChange={(event, newTab) => handleTabChange(newTab)}
						aria-label="text alignment"
					>
						<ToggleButton
							value="Bioquimicos"
							aria-label="Bioquimicos"
							className={tabSection === "Bioquimicos" ? "active" : ""}
							sx={{
								width: '149px',
								height: '40px',
								flexShrink: 0,
							}}
						>
							Bioquímicos
						</ToggleButton>
						<ToggleButton
							value="Varios"
							aria-label="Varios"
							className={tabSection === "Varios" ? "active" : ""}
							sx={{
								width: '149px',
								height: '40px',
								flexShrink: 0,
							}}
						>
							Varios
						</ToggleButton>
					</ToggleButtonGroup>
					<div className="btn-filter">
						<h3 className="text-filter">Filtro</h3>
						<Tooltip title="A-Z" arrow>
							<button className="icon-filter" onClick={handleSortAZ}>
								<img src={IconFilter} alt="icono" />
							</button>
						</Tooltip>
						<Tooltip title="Mayor a menor" arrow>
							<button className="icon-filter" onClick={handleSortCodeAsc}>
								<img src={IconFilterNum} alt="icono" />
							</button>
						</Tooltip>
					</div>
				</div>



				<div
					style={{
						// zIndex para que el day picker se muestre encima de la pagination
						zIndex: "3",
					}} 
				>
				</div>
				<div className="table-container">
					<div className="table-header">
						{currentProducts.length > 0 ? (
							<Table responsive className="">
								<thead onClick={deselect}>
									<tr>
										<th className="title-th"></th>
										<th className="title-th">Codigo</th>
										<th className="title-th">Nombre</th>
										<th className="title-th">Marca</th>
										{isBioSection && (
											<>
												
												<th className="title-th">NBU</th>
											</>
										)}
										{!isBioSection && <th className="title-th">Categoría</th>}
										<th className="title-th">Cantidad</th>
										<th className="title-th">Peso/Volum</th>
										<th className="title-th">Tipo de Unidad</th>
										<th className="title-th">Proveedor</th>
										<th className="title-th">Ver más</th>
									</tr>
								</thead>
								<tbody className="tbody">
									{currentProducts.map((product, index) => (
										<ItemRow
											key={product._id}
											item={product}
											select={select}
											deselect={deselect}
											itemSelected={productSelected}
											isBioSection={isBioSection}
											isTag={product.hasOwnProperty("isProducts")}
											isEven={isEven(index)}
											allCategories={allCategories}
										/>
									))}
								</tbody>
							</Table>

						) : (
							<div className="spinner" style={{display: 'flex', justifyContent:'center'}}>
								<Spinner 
									animation="border"
									role="status"
								>
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Popup */}
			{
				variable ? (
					<AnimacionSuccessful
						errorBool={errorBool}
						avisomsg={avisomsg}
						refreshPage={refreshPage}
					/>
				) : (
					<></>
				)
			}

			<Pagination className="pagination-all-tag">
				{showFirstPage && (
					<Pagination.Prev
						onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
						disabled={currentPage === 1}
					/>
				)}
				{visiblePages.map(page => (
					<Pagination.Item
						className="pag-item-all-tag"
						key={page}
						active={page === currentPage}
						onClick={() => setCurrentPage(page)}
					>
						{page}
					</Pagination.Item>
				))}
				{showLastPage && (
					<Pagination.Next
						onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
						disabled={currentPage === totalPages}
					/>
				)}
			</Pagination>

			<div className="button-footer">
				<AbmButtons
					item={productSelected}
					abm={isBioSection ? "productos" : "productosVarios"}
					funcion={isBioSection ? deleteProduct : deleteOtherProduct}
					role={isAdmin}
					providers={selectProviders}
					isLoaded={isLoaded}
					onProviderChange={handleProviderChange}
					okDeleteAllProvProducts={deleteAllProductsById}
				/>


			</div>

			{/* Modal de manejo de eliminacion de producto */}
			<ProductDeleteHandlerModal
				ordersThatContainsProduct={ordersThatContainsProduct}
				showProductDeleteHandlerModal={showProductDeleteHandlerModal}
				closeProductDeleteHandlerModal={() => setShowProductDeleteHandlerModal(false)}
			/>
			{/* Modal de carga de productos */}
			<NestedModal open={openNestedModal} handleClose={handleCloseNestedModal} />
			{/* Modal de exportación a PDF */}

			<PdfExportModal
				show={openPdfExportModal}
				onClose={handleClosePdfExportModal}
				providers={selectProviders}
				isLoaded={isLoaded}
			/>
		</div >
	)
}