import React from "react"
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import innovis_adobe_express from "./../../../Assets/Img/innovis_adobe_express.png"

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		alignItems: "center",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	membrete: {
		paddingLeft: 15,
	},
	logo: {
		width: 140,
		height: 90,
		margin: 20,
	},
	title: {
		fontSize: 38,
		fontWeight: "bold",
	},
	highlight: {
		color: "#fff",
		fontSize: 13,
		backgroundColor: "rgb(47, 161, 161)",
		padding: 1,
	},
	subtitle: {
		fontSize: 15,
		fontWeight: "bold",
	},
	campo: {
		fontSize: 15,
		fontWeight: "bold",
	},
	cardInfo: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	info: {
		fontSize: 12,
		fontWeight: "light",
		textAlign: "left",
		paddingTop: 1,
	},
	date: {
		textAlign: "center",
		fontSize: 12,
		paddingLeft: 35,
	},
	spacer: {
		width: 40,
	},
	table: {
		display: "table",
		width: "90%",
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		// margin: "auto",
		flexDirection: "row",
		//width: "90%"
	},
	tableRowTitle: {
		flexDirection: "row",
	},
	tableCol: {
		width: "30%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableColFirst: {
		width: "30%",
		borderStyle: "solid",
		borderWidth: 1,
		borderTopWidth: 0,
	},
	tableCell: {
		margin: "auto",
		marginTop: 5,
		fontSize: 10,
		padding: 2,
	},
	tableTitle: {
		margin: "auto",
		marginTop: 5,
		fontSize: 12,
		color: "rgb(47, 161, 161)",
		padding: 2,
	},
})

const StockProductToPdf = ({ products, sender, isBiochemicalsSection }) => {
	const titulo = "ORDEN DE COMPRA"
	const highlight = "DOCUMENTO NO VÁLIDO COMO FACTURA"

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.header}>
					<View style={styles.membrete}>
						<Text style={styles.title}>{titulo}</Text>
						<View style={styles.spacer} />
						<Text style={styles.highlight}>{highlight}</Text>
						<View style={styles.spacer} />
						<Text style={styles.subtitule}>INNOVIS LABORATORIOS BIOQUÍMICOS</Text>
						<View style={styles.spacer} />
						<View style={styles.cardInfo}>
							<Text style={styles.campo}>{"Razón Social: "} </Text>
							<Text style={styles.info}>{sender.razonSocial}</Text>
						</View>
						<View style={styles.cardInfo}>
							<Text style={styles.campo}>{"CUIT: "}</Text>
							<Text style={styles.info}>{sender.cuit}</Text>
						</View>
						<View style={styles.cardInfo}>
							<Text style={styles.campo}>{"Dirección de envío: "} </Text>
							<Text style={styles.info}>{sender.address}</Text>
						</View>
						<View style={styles.spacer} />
					</View>
					<View style={styles.membrete}>
						<Image
							src={innovis_adobe_express}
							style={styles.logo}
						/>
						<Text style={styles.info}>Laboratorio bioquímico integral</Text>
						<Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
					</View>
				</View>
				<View style={styles.table}>
					<View style={styles.tableRowTitle}>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Código</Text>
						</View>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Producto</Text>
						</View>
						{isBiochemicalsSection && (
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Marca</Text>
							</View>
						)}
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Cantidad Pedida</Text>
						</View>
					</View>
					{products.map((product, index) => {
						return (
							<View
								style={styles.tableRow}
								key={index}
							>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product.code || "-"}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product.name || "-"}</Text>
								</View>
								{isBiochemicalsSection && (
									<View style={styles.tableCol}>
										<Text style={styles.tableCell}>{product.brand || "-"}</Text>
									</View>
								)}
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product.quantityToOrder}</Text>
								</View>
							</View>
						)
					})}
				</View>

			</Page>
		</Document>
	)
}

export default StockProductToPdf
