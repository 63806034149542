import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from 'moment';
import axios from "axios";
import Cookies from 'js-cookie';
import '../Main.css';
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const SedeMain = () => {
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment().endOf('month'));
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    //-------------POST------------
    const updateUser = (id, startDateOrder, endDateOrder) => {
        console.log(". start: ", startDateOrder, ". end: ", endDateOrder)
        axios({
            method: "put",
            url: `${BACK_APP_URI}/users/edit/${id}`,
            data: {
                startDateOrder: startDateOrder,
                endDateOrder: endDateOrder
            },
        }).then(function (response) {
            console.log(response)
            //alert("Usuario actualizado correctamente");
            navigate("/main");
        });
    }
    useEffect(() => {
        async function getDataUser() {
            const token = Cookies.get("token")
            const result = await axios.get(
                `${BACK_APP_URI}/users`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            )
                .then((res) => {
                    console.log("Data", res);
                    setUsers(res.data.users);
                });
        }
        getDataUser()
    }, []);
    // Actualizar el rango al mes siguiente
    useEffect(() => {
        const sede = Cookies.get('username');
        const pass = Cookies.get('password');

        async function updateRange() {
            const sedeUser = users.find((user) => user.username === sede);
            if (!sedeUser) return;

            const endDateOrder = moment(sedeUser.endDateOrder);
            const currentDate = moment();

            if (currentDate.isAfter(endDateOrder) && currentDate.diff(endDateOrder, 'days') >= 1) {
                // Establecer la fecha de inicio como el día 20 del mes actual
                let startOfCurrentMonth = moment().startOf('month').date(20);

                // Establecer la fecha de término como el día 4 del siguiente mes
                let endOfNextMonth = moment().add(1, 'month').startOf('month').date(4);

                // Verificar si el día 4 cae en sábado (6) o domingo (0)
                if (endOfNextMonth.weekday() === 5) {
                    // Si es sábado, sumar 2 días para moverlo al lunes
                    endOfNextMonth = endOfNextMonth.add(2, 'days');
                } else if (endOfNextMonth.weekday() === 6) {
                    // Si es domingo, sumar 1 día para moverlo al lunes
                    endOfNextMonth = endOfNextMonth.add(1, 'days');
                }
                // Actualizar el estado con las nuevas fechas
                setStartDate(startOfCurrentMonth);
                setEndDate(endOfNextMonth);

                updateUser(
                    sedeUser._id,
                    startOfCurrentMonth,
                    endOfNextMonth
                );
            }
        }

        updateRange();
    }, [users]);

    return (
        <div className="main-container">
            <div className="header-sede-main" >
                <h1 className="title-sede-main">Nuevo pedido</h1>
                <Link
                    to={"/orders/add"}
                    className="mx-auto"
                >
                    <Button
                        className="button-sede-main">
                        Generar pedido
                    </Button >
                </Link>

            </div>

        </div>
    );
}

export default SedeMain;
