import React, { useEffect, useState } from "react"
import { Button, Form, Modal, Nav, Table } from "react-bootstrap"
import Cookies from "js-cookie"
import axios from "axios"
import { PDFDownloadLink } from "@react-pdf/renderer"
import AnimacionSuccessful from "../AnimacionSuccessful/animacionSuccessful"
import PdfIcon from "../../Assets/Img/pdf17.svg"
import provHistory from "../../Assets/Img/icon-next.svg"
import IconSend from '../../Assets/Img/send.svg';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import iconAdd from "./../ABMbotones/iconButtonAbm/iconAdd2.svg"
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import "./ProviderOrder.css"
import ProductsTable from "./Components/ProductsTable"
import SendOrderModal from "./Components/SendOrderModal"
import StockProductToPdf from "./Components/StockProductsToPdf"
import NewProviderProduct from "./Components/NewProviderProduct"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const ProviderOrder = () => {
	const [productsOutOfStock, setProductsOutOfStock] = useState([])
	const [productsInStock, setProductsInStock] = useState([])
	const [selectedProvider, setSelectedProvider] = useState("")
	const [selectedProviderName, setSelectedProviderName] = useState("")
	const [allProviders, setAllProviders] = useState([])
	const [allUnits, setAllUnits] = useState([])
	const [showEmailModal, setShowEmailModal] = useState(false)
	const [productsManuallyAdded, setProductsManuallyAdded] = useState([])
	const [infoSender, setInfoSender] = useState({
		razonSocial: "",
		cuit: "",
		address: "",
	})
	const [avisomsg, setAvisomsg] = useState("")
	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [tabSection, setTabSection] = useState("Bioquimicos")

	//-------------------NODEMAILER------------------------------------------------

	//Asignación de productos sin stock a la tabla Orden.
	useEffect(() => {
		;(async () => {
			const token = Cookies.get("token")

			const {
				data: { tags: allTags },
			} = await axios.get(`${BACK_APP_URI}/tags`, {
				headers: {
					Authorization: `${token}`,
				},
			})

			const productsAndTags = [
				...allTags.filter(tag => tag.name !== "-" && (tabSection === "Bioquimicos" ? tag.isProducts : !tag.isProducts)),
				...(tabSection === "Bioquimicos" ? allTags.find(tag => tag.name === "-").products : allTags.find(tag => tag.name === "-").otherProducts),
			].map(product => {
				return { ...product, quantityToOrder: 0 }
			})

			setProductsOutOfStock(productsAndTags.filter(product => product.quantity < product.usoSmnal && product.provider._id === selectedProvider))
			setProductsInStock(productsAndTags.filter(product => product.quantity >= product.usoSmnal && product.provider._id === selectedProvider))
		})()
	}, [selectedProvider, tabSection])

	useEffect(() => {
		;(async () => {
			const token = Cookies.get("token")
			// Proveedores
			await axios
				.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setAllProviders(result.data.provider)
				})

			// Unidades
			await axios
				.get(`${BACK_APP_URI}/unidades`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setAllUnits(result.data.units)
				})
		})()
	}, [])

	const handleManuallyAddProduct = (id, code, ubn, name, brand, quantityToOrder, measure) => {
		const newData = {
			_id: id,
			code,
			ubn,
			name,
			brand,
			quantityToOrder,
			selectedProviderName,
			measure: { name: measure, _id: allUnits.find(unit => unit.name === measure)._id },
		}
		setProductsManuallyAdded(prev => [...prev, newData])
	}
	const handleRemoveManuallyAddedProduct = id => {
		setProductsManuallyAdded(prev => prev.filter(prevProduct => prevProduct._id !== id))
	}

	const handleProductToInStockTable = id => {
		setProductsInStock(prev => [...prev, productsOutOfStock.find(product => product._id === id)])
		setProductsOutOfStock(prev => prev.filter(product => product._id !== id))
	}

	const handleProductToOutOfStockTable = id => {
		setProductsOutOfStock(prev => [...prev, productsInStock.find(product => product._id === id)])
		setProductsInStock(prev => prev.filter(product => product._id !== id))
	}

	const handleRazonSocial = e => {
		const selectedProviderId = e.target.value
		const selectedProviderObject = allProviders.find(provider => provider._id === selectedProviderId)
		const razon = selectedProviderObject ? selectedProviderObject.name : ""
		const cuit = selectedProviderObject ? selectedProviderObject.cuit : ""
		const address = selectedProviderObject ? selectedProviderObject.address : ""
		setInfoSender({
			razonSocial: razon,
			cuit: cuit,
			address: address,
		})
	}

	const createOrderHistory = async (provider, sender, products) => {
		try {
			await axios.post(`${BACK_APP_URI}/historial_pedidos/add`, { provider, sender, products, isProducts: tabSection === "Bioquimicos" })
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("Pdf agregado al Historial!")
			setTimeout(() => {
				setVariable(false)
			}, 2000)
		} catch (error) {
			console.log("Error al agregar historial de pedido:", error.message)
			setErrorBool(true)
			setAvisomsg(error?.response?.data?.msg)
			setVariable(true)
			setTimeout(() => {
				setVariable(false)
			}, 3200)
		}
	}

	const handleTabChange = section => {
		setTabSection(section)
		setProductsManuallyAdded([])
	}

	return (
		<>
			<div className="main-container">
				<div className="header--container">
					
					<div className="provider-row">
						<div className="">
							<h1 className="title--header">Pedido Proveedores</h1>
							
						</div>
						
						<Link
							to="/provider-order/history"
							className="link-prov-history"
						>
							Historial de pedidos
							<img
								src={provHistory}
								alt="Historial de pedidos"
								className="icon"
							/>{" "}
							
						</Link>
					</div>
					</div>
					<div className="card--container">
						<div className="card--header--row ">
						<div className="select-order">
						<ToggleButtonGroup
                            value={tabSection}
                            exclusive
                            onChange={(event, newTab) => handleTabChange(newTab)}
                            aria-label="text alignment"
                        >
                            <ToggleButton
                                value="Bioquimicos"
                                aria-label="Bioquimicos"
                                className={tabSection === "Bioquimicos" ? "active" : ""}
                                sx={{
                                    width: '149px',
                                    height: '40px',
                                    flexShrink: 0,
                                }}
                            >
                                Bioquímicos
                            </ToggleButton>
                            <ToggleButton
                                value="Varios"
                                aria-label="Varios"
                                className={tabSection === "Varios" ? "active" : ""}
                                sx={{
                                    width: '149px',
                                    height: '40px',
                                    flexShrink: 0,
                                }}
                            >
                                Varios
                            </ToggleButton>
                        </ToggleButtonGroup>

				

				
				
					<div className="">
					<h2 className="title-select-order">Origen</h2>
						<Form.Select
							className="select-provider"
							onChange={handleRazonSocial}
						>
							<option value="">Razón social</option>
							{allProviders.map(provider => (
								<option
									key={provider._id}
									value={provider._id}
								>
									{provider.name}
								</option>
							))}
						</Form.Select>
						
						</div>
					
					
					<div className="">
					<h2 className="title-select-order" >Destinatario</h2>
						<Form.Select
						
							className="select-provider"
							onChange={e => {
								setSelectedProvider(e.target.value)
								setSelectedProviderName(e.target.options[e.target.selectedIndex].getAttribute("name"))
							}}
						>
							<option value="">Proveedor</option>
							{allProviders.map((provider, i) => (
								<option
									key={provider._id}
									value={provider._id}
									name={provider.name}
								>
									{provider.name}
								</option>
							))}
						</Form.Select>
						
						</div>
						</div>
					</div>
				
              <div className="table-container">
				<div className="table-striped table-bordered table-hover">
					
					{selectedProvider === "" || infoSender.razonSocial === "" ? (
						<div className="text-card-tabla">
						<p><strong>Seleccione:</strong> Razón social y proveedor para continuar.</p>
						</div>
					) : (
						
						<>
							<ProductsTable
								productsArr={productsOutOfStock}
								setProductsArr={setProductsOutOfStock}
								onButtonClick={handleProductToInStockTable}
								productsManuallyAddedArr={productsManuallyAdded}
								setProductsManuallyAdded={setProductsManuallyAdded}
								handleRemoveManuallyAddedProduct={handleRemoveManuallyAddedProduct}
								isBiochemicalsSection={tabSection === "Bioquimicos"}
								productsPerPage={8}
							/>
                         
							<div className="table-striped table-bordered table-hover">
								<div className="text-table">Agregar producto en stock</div>
								<ProductsTable
									productsArr={productsInStock}
									setProductsArr={setProductsInStock}
									onButtonClick={handleProductToOutOfStockTable}
									isBiochemicalsSection={tabSection === "Bioquimicos"}
									isInStockTable
									productsPerPage={3}
								/>
							</div>

							<div>
								<div className="text-table">Agregar producto manualmente</div>
								<NewProviderProduct
									onAddData={handleManuallyAddProduct}
									isBiochemicalsSection={tabSection === "Bioquimicos"}
									allUnits={allUnits}
								/>
							</div>
							< div className="buttons-prov-container">
						<Tooltip title="Exportar pdf" arrow>
							<Button
								className="btn-provider-pdf"
								disabled={!productsOutOfStock.length && !productsManuallyAdded.length}
							>
								<PDFDownloadLink
									document={
										<StockProductToPdf
											products={[...productsOutOfStock, ...productsManuallyAdded]}
											sender={infoSender}
											isBiochemicalsSection={tabSection === "Bioquimicos"}
										/>
									}
									fileName="OrdenCompra"
									onClick={e => {
										createOrderHistory(
											selectedProviderName,
											infoSender,
											[...productsOutOfStock, ...productsManuallyAdded].map(product => ({ ...product, measure: product?.measure || product?.unitType }))
										)
									}}
								>
									<img
										className="icon-btn"
										src={PdfIcon}
										alt="camera capture"
									/>
								</PDFDownloadLink>
							</Button>
                            </Tooltip>
							<Button
								
								className="btn-provider-send"								
								onClick={() => {
									setShowEmailModal(true)
								}}
							>
								{" "}
								<img className="icon-send" src={IconSend} alt="icono" />
								Enviar Pedido
							</Button>
							</div>
					
						</>
					)}
				</div>
				</div>
				<SendOrderModal
					allProviders={allProviders}
					setShowEmailModal={setShowEmailModal}
					showEmailModal={showEmailModal}
				/>

				{variable && (
					<AnimacionSuccessful
						errorBool={errorBool}
						avisomsg={avisomsg}
					/>
				)}
				</div>
			</div>
		</>
	)
}
export default ProviderOrder
