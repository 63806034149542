import React, { useEffect, useState } from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import innovis_adobe_express from "./../../../Assets/Img/innovis_adobe_express.png";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  membrete: {
    paddingLeft: 15,
  },
  pt_10: {
    paddingTop: 10,
  },
  logo: {
    width: 140,
    height: 90,
    margin: 20,
  },
  title: {
    fontSize: 34,
    fontWeight: "bold",
  },
  highlight: {
    color: "#fff",
    fontSize: 13,
    backgroundColor: "rgb(47, 161, 161)",
    padding: 1,
  },
  subtitle: {
    fontSize: 15,
    fontWeight: "bold",
  },
  campo: {
    fontSize: 15,
    fontWeight: "bold",
  },
  cardInfo: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  info: {
    fontSize: 12,
    fontWeight: "light",
    textAlign: "left",
    paddingTop: 1,
  },
  date: {
    textAlign: "center",
    fontSize: 12,
    paddingLeft: 35,
  },
  spacer: {
    width: 40,
  },
  table: {
    display: "table",
    width: "90%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRowTitle: {
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 4,
    marginBottom: 4,
    fontSize: 8,
    padding: 1,
  },
  tableTitle: {
    margin: "auto",
    marginTop: 4,
    marginBottom: 4,
    fontSize: 8,
    color: "rgb(47, 161, 161)",
    padding: 1,
  },
});





const OrderPDF = ({ orderInfo, orderProduct, orderOtherProducts, tabSection }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (tabSection === "biochemicals") {
      setProducts(orderProduct || []);
    } else {
      setProducts(orderOtherProducts || []);
    }
  }, [orderProduct, orderOtherProducts, tabSection]);

  const getTableTitle = () => {
  if (orderInfo.isAccepted) {
    return 'Cantidad Enviada';
  }
  return 'Pendiente de Aceptación';
};

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={styles.membrete}>
            <Text style={styles.title}>
              {orderInfo.role === 'sede' ? 'PEDIDO SOLICITADO' : 'PEDIDO ENVIADO'}
            </Text>
            <View style={styles.pt_10} />
            <Text style={styles.subtitle}>NÚMERO DE PEDIDO: {orderInfo.nroPedido}</Text>
            <Text style={styles.subtitle}>SEDE: {orderInfo.lab}</Text>
            <View style={styles.spacer} />
          </View>
          <View style={styles.membrete}>
            <Image src={innovis_adobe_express} style={styles.logo} />
            <Text style={styles.info}>Laboratorio bioquímico integral</Text>
            <Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
          </View>
        </View>

        {/* Tabla de productos */}
        <View style={styles.table}>
          <View style={styles.tableRowTitle}>
            <View style={styles.tableCol}>
              <Text style={styles.tableTitle}>Nombre</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableTitle}>{getTableTitle()}</Text>
            </View>
          </View>

          {/* Iteramos sobre los productos */}
          {products.map((product) => {
            const isTagged = product.isTag ?? false;
            const name = product?.data?.name || 'Producto desconocido';

            let quantityToSend = 0;
            if (isTagged && Array.isArray(product.quantityToSend)) {
              quantityToSend = product.quantityToSend.reduce(
                (total, item) => total + (item?.quantityToSend ?? 0),
                0
              );
            } else {
              quantityToSend = product.quantityToSend ?? product.quantityRequested ?? 0;
            }

            return (
              <View
                style={[styles.tableRow, { borderTopWidth: 1, borderTopColor: "#000", borderTopStyle: "solid" }]}
                key={product.data?._id || product._id}
              >
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{quantityToSend}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};


export default OrderPDF;
